import * as styles from '../sections/landing1/Home.module.css'
import React from 'react'
import MetaData from '../components/MetaData'
import styled from 'styled-components'
import PageWrapper from '../components/PageWrapper'
import Hero from '../sections/common/Hero'
import { Container, Row, Col } from 'react-bootstrap'
import { Title, Text, Section } from '../components/Core'

import TabletMobile from '../assets/image/png/tablet-mobile.png'
import AppStore from '../assets/image/svg/appstore-black.svg'
import PlayStore from '../assets/image/svg/playstore-colors.svg'
import WebApp from '../assets/image/png/hero-image.png'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const ImageShadow = styled.img`
  -webkit-filter: drop-shadow(
    ${({ theme }) => `0 52px 42px ${theme.colors.shadow}`}
  );
  filter: drop-shadow(${({ theme }) => `0 52px 42px ${theme.colors.shadow}`});
`

const Apps = () => {
  const { t, ready } = useTranslation(['app'], { useSuspense: false })
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  return ready && (
    <>
      <MetaData page="default" />
      <PageWrapper headerDark footerDark>
        <Hero bg={userDarkMode ? 'black' : 'bg'} title={t('apps')}>
          {t('integration')}
        </Hero>

        <Section bg={userDarkMode ? 'black' : 'bg'}>
          <Container>
            <Row className="d-flex align-items-center pb-4">
              <Col lg="5">
                <ImageShadow
                  width="100%"
                  height="auto"
                  className="img-fluid"
                  data-aos="fade-down"
                  data-aos-duration="750"
                  data-aos-once="true"
                  alt={t('mainImg')}
                  src={TabletMobile}
                />
              </Col>
              <Col lg="6" className="pr-lg-5 pr-2">
                <Title color={userDarkMode ? 'light' : 'dark'}>
                  {t('mobile')}
                </Title>
                <Text color={userDarkMode ? 'light' : 'dark'}>
                  {t('tradeOnTheGo')}
                </Text>
                <Text color={userDarkMode ? 'light' : 'dark'} className="mb-4">
                  {t('theMostComprehensive')}
                </Text>
                <a
                  href="https://itunes.apple.com/app/coinigy/id1317482120?mt=8"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mr-4"
                >
                  <img
                    width="120px"
                    height="40px"
                    src={AppStore}
                    alt={t('appStoreImg')}
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.coinigy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    width="135px"
                    height="40px"
                    src={PlayStore}
                    alt={t('googlePlayImg')}
                  />
                </a>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section pt={[0, null, 0, 0]} bg={userDarkMode ? 'black' : 'bg'}>
          <Container>
            <Row className="d-flex align-items-center pb-4">
              <Col lg="7" className="order-lg-2">
                <ImageShadow
                  width="100%"
                  height="auto"
                  className="img-fluid mb-4"
                  data-aos="fade-down"
                  data-aos-duration="750"
                  data-aos-once="true"
                  alt={t('mainImg')}
                  src={WebApp}
                />
              </Col>
              <Col lg="5" className="pl-lg-5 order-lg-1">
                <Title color={userDarkMode ? 'light' : 'dark'}>Web</Title>
                <Text color={userDarkMode ? 'light' : 'dark'} className="mb-5">
                  {t('theMostComprehensive')}
                </Text>
                <a
                  className={styles.aTagButton}
                  href="/auth/login"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('launchWebApp')}
                </a>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section bg={userDarkMode ? 'black' : 'bg'}>
          <Container>
            <Row className="justify-content-center pb-4">
              <Col lg="4">
                <Title color={userDarkMode ? 'light' : 'dark'}>
                  {t('tradingTools')}
                </Title>
              </Col>
              <Col lg="8" className="pl-lg-5">
                <Text color={userDarkMode ? 'light' : 'dark'}>
                  {t('arbitrageOps')}
                  <strong> ArbMatrix.</strong>
                </Text>
                <Text color={userDarkMode ? 'light' : 'dark'}>
                  {t('tradingSetups')}
                  <strong> Bitcoin Chart Scanner.</strong>
                </Text>
                <Text color={userDarkMode ? 'light' : 'dark'}>
                  {t('upToDate')}
                  <strong> SocialScanner.</strong>
                </Text>
                <Text color={userDarkMode ? 'light' : 'dark'}>
                  {t('bestTools')}
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}
export default Apps
